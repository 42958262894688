<template>
  <v-dialog v-model="cancelDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="warning"
        v-show="!!paddedCardNumber"
        v-bind="attrs"
        v-on="on"
        >Remove Card</v-btn
      >
    </template>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2"> Cancel Card </v-card-title>
      <v-card-text>
        <p>We will remove your card. Are you sure?</p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="warning"
          :loading="cancellingCard"
          @click="cancelUserCard"
        >
          Yes, Please Remove!
        </v-btn>
        <v-btn text @click="cancelDialog = false"> Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    cancellingCard: false,
    cancelDialog: false,
  }),
  props: ["userid", "paddedCardNumber"],
  mounted() {
    const { userid, paddedCardNumber } = this;
    console.log("log", { userid, paddedCardNumber });
  },

  watch: {
    userid: function (val) {
      console.log("log-val", val);
    },
    paddedCardNumber: function (val) {
      console.log("log-Padded", val);
    },
  },
  methods: {
    async cancelUserCard() {
      try {
        this.cancellingCard = true;
        await this.$store.dispatch("profile/cancelUserCard", this.userid);
        this.cancelDialog = false;
      } catch (err) {
        console.log("errror", err);
      } finally {
        this.cancellingCard = false;
      }
    },
  },
};
</script>