<template>
  <v-card color="white lighten-1">
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-container fluid>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="templateForm.templateName"
                :items="templates"
                label="Template:"
                @change="getTemplate"
                required
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="templateForm.subject"
                :counter="10"
                :rules="subjectRules"
                label="Subject"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="8">
              <v-textarea
                v-show="mode === 'editor'"
                background-color="grey lighten-2"
                :rows="15"
                name="content"
                label="Content:"
                :value="templateForm.content"
                v-debounce:300ms="setContent"
                hint="Email Content"
                required
              ></v-textarea>
              <div class="content" v-show="mode === 'html'" v-html="html"></div>
              <div class="content" v-show="mode === 'text'">{{ text }}</div>
            </v-col>
            <v-col cols="4">
               <b>Data List</b>
              <v-list dense>
                <v-list-item
                  v-for="(item, i) in dataList"
                  :key="i + '-' + item"
                >
                  {{ item }}
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="6">
              <v-btn
                :loading="loading"
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="updateTemplate"
              >
                Submit
              </v-btn>
            </v-col>

            <v-col cols="6">
              <v-radio-group v-model="mode" row>
                <v-radio label="Editor" value="editor"></v-radio>
                <v-radio label="Html" value="html"></v-radio>
                <v-radio label="Text" value="text"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
const md = require("markdown-it")();
const { htmlToText } = require("html-to-text");
const TurndownService = require("turndown").default;

const turndownService = new TurndownService();

export default {
  data: () => ({
    loading: false,
    mode: "editor",
    valid: false,
    templateName: null,
    templateForm: {
      subject: "",
      content: "",
      templateName: "",
    },
    subjectRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 200) || "Name must be less than 200 characters",
    ],
    dataList: [],
  }),
  computed: {
    loading1() {
      return this.$store.getters["emailTemplate/loading"];
    },
    templates() {
      return this.$store.getters["emailTemplate/templateNames"];
    },
    template() {
      return this.$store.state.emailTemplate.template;
    },

    html() {
      return md.render(this.templateForm.content || "");
    },
    text() {
      return htmlToText(this.html || "", {
        wordwrap: 130,
      });
    },
  },

  watch: {
    loading: function (val) {
      console.log("loading", val);
    },
    html: function (val) {
      console.log("html", val);
    },
    text: function (val) {
      console.log("text", val);
    },
    template: function (val) {
      console.log("watch template", val);
      if (val) {
        this.$set(this.templateForm, "subject", val.subject);
        this.$set(
          this.templateForm,
          "content",
          turndownService.turndown(val.html)
        );
        this.dataList.splice(0);
        this.dataList.push(...(val.dataList || []));
      } else {
        this.$set(this.templateForm, "subject", "");
        this.$set(this.templateForm, "content", "");
        this.dataList = [];
      }
    },
  },
  mounted() {
    this.$store.dispatch("emailTemplate/listEmailTemplates");
  },

  methods: {
    setContent(val) {
      this.templateForm.content = val;
      console.log("setContent", val);
    },
    getTemplate(templateName) {
      console.log("templateName", templateName);
      this.$store.dispatch("emailTemplate/getEmailTemplate", templateName);
    },
    async updateTemplate() {
      this.$refs.form.validate();
      if (this.valid) {
        const { templateName, subject } = this.templateForm;
        const { html, text } = this;
        this.loading = true;
        await this.$store.dispatch("emailTemplate/updateEmailTemplate", {
          templateName,
          subject,
          html,
          text,
        });
        this.loading = false;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>
<style scoped>
div.content {
  white-space: pre-wrap;
  height: 400px;
  width: 100%;
  background-color: powderblue;
}
.v-list {
  height: 400px;
  overflow-y: auto;
}
</style>