<template>
  <div>
    <div class="text-right">
      <v-tabs v-model="adminTab" align-with-title>
        <v-tab>Loans</v-tab>
        <v-tab>Settings</v-tab>
        <v-tab>Templates</v-tab>
      </v-tabs>
    </div>

    <v-tabs-items v-model="adminTab">
      <v-tab-item>
        <v-lazy v-model="loanState">
          <customer-view
            v-if="!!(loan && loan.id)"
            :items="adminLoanTabItems"
            :personal-info="personalInfo"
            @get-document="getDocument"
          >
            <template #title>Loan Details</template>
            <template #control>
              <v-spacer></v-spacer>
              <v-btn icon @click="exitLoan">
                <v-icon>mdi-exit-to-app</v-icon>
              </v-btn>
            </template>

            <template #card-actions>
              <manage-customer-card
                :userid="userid"
                :padded-card-number="paddedCardNumber"
              />
            </template>

            <template #admin-actions>
              <manage-loan-statuses :loan="loan" />
            </template>

            <template #loan-transactions>
              <v-simple-table dense>
                <template #default>
                  <thead>
                    <tr>
                      <th class="text-left">Type</th>
                      <th class="text-left">Date</th>
                      <th class="text-right">Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="trx in loan.transactions" :key="trx.id">
                      <td>
                        {{ trx.type }}
                      </td>
                      <td>
                        {{ trx.created | filterDate }}
                      </td>
                      <td class="text-right">
                        {{ trx.amount | toCurrency }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>

            <template #file-control="{ filekey }">
              <v-btn @click="getDocument(filekey)">
                <v-icon color="blue darken-2"> mdi-download </v-icon>
              </v-btn>
            </template>
          </customer-view>

          <v-card v-else>
            <v-card-title>Loans Management </v-card-title>
            <v-data-table
              :headers="headers"
              :items="loans"
              item-key="id"
              class="elevation-1"
              :search="search"
              :custom-filter="filterLoans"
              :loading="loading"
            >
              <template #top>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="statusSearch"
                        :items="loanStatuses"
                        label="Select Status"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="applicantSearch"
                        label="Search Applicants"
                        class="mx-4"
                        append-icon="mdi-magnify"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <v-spacer></v-spacer>
              </template>

              <template #progress>
                <div class="text-center">
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </template>

              <template v-slot:[`item.userid`]="{ item }">
                <td>{{ item.userid | filterApplicantName(applicants) }}</td>
              </template>
              <template v-slot:[`item.principal`]="{ item }">
                <td>{{ item.principal | toCurrency }}</td>
              </template>
              <template v-slot:[`item.date_applied`]="{ item }">
                <td>{{ item.date_applied | filterDate }}</td>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <td>{{ item.status | displayStatus }}</td>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <td>
                  <v-btn
                    @click="setLoan(item.id)"
                    :loading="item.id === loaderId"
                    >Select</v-btn
                  >
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-lazy>
      </v-tab-item>
      <v-tab-item>
        <v-lazy>
          <admin-settings> Settings </admin-settings>
        </v-lazy>
      </v-tab-item>
      <v-tab-item>
        <v-lazy>
          <email-template></email-template>
        </v-lazy>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import graphqlMixin from "@/mixins/graphql-mixin.js";

import statusMixins from "@/mixins/statusMixins.js";

import CustomerView from "@/components/CustomerView";
import ManageLoanStatuses from "@/components/ManageLoanStatuses";
import ManageCustomerCard from "@/components/ManageCustomerCard";
import AdminSettings from "@/components/AdminSettings";
import EmailTemplate from "@/components/EmailTemplate";
import { formaTabView } from "@/helpers/formatPersonInfo";
import * as changeCase from "change-case";

export default {
  components: {
    CustomerView,
    AdminSettings,
    EmailTemplate,
    ManageLoanStatuses,
    ManageCustomerCard,
  },
  mixins: [graphqlMixin, statusMixins],
  data: () => ({
    adminTab: null,
    loanState: null,

    loaderId: null,

    search: "",
    statusSearch: "Any",
    applicantSearch: null,
    applicant: null,
    cardData: {},
  }),
  filters: {
    toCase(value, type) {
      return changeCase[type](value);
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return value > 0
        ? formatter.format(value)
        : "(" + formatter.format(-value) + ")";
    },
    filterDate(value) {
      return value.split("T")[0];
    },
    filterApplicantName(value, applicants) {
      let applicant = applicants.find((x) => x.userid === value);
      if (!applicant) return "";
      return `${applicant.given_name} ${applicant.family_name}`;
    },
  },
  computed: {
    loading() {
      return this.$store.state.admin_loan.loading;
    },

    adminLoanTabItems() {
      if (!this.loan || !this.loan.id) return [];
      const items = [
        "Card",
        "Personal",
        "Contact",
        "Employment",
        "Finance",
        "Banking",
        "Documents",
      ];

      if (this.loan.transactions.length > 0) {
        items.unshift("Transactions");
      }
      items.unshift("Loan");
      return items;
    },

 

    headers() {
      return [
        {
          text: "Applicant",
          align: "start",
          sortable: false,
          value: "userid",
          filterable: true,
        },

        { text: "Amount", value: "principal", filterable: false },
        { text: "Currency", value: "currency", filterable: false },
        { text: "Date", value: "date_applied", filterable: false },
        { text: "Status", value: "status", filterable: true },
        { text: "", value: "id", filterable: false },
      ];
    },
    loans() {
      return (this.$store.state.admin_loan.loans || []).map((x) => {
        x.principal = x.principal || x.amount_requested;
        return x;
      });
    },
    loan() {
      return this.$store.state.admin_loan.loan;
    },

    formattedLoanData() {
      const {
        profile_certified,
        status,
        amount_requested,
        principal,
        currency,
        date_applied,
        date_required,
        date_approved,
        date_issued,
        disbusement_date,
        date_first_payment_due,
        tern,
        days,
        installment,
        type,
        payment_method,
        comments,
      } = this.loan;
      //re-order
      return {
        profile_certified,
        status,
        amount_requested,
        principal,
        currency,
        date_applied,
        date_required,
        date_approved,
        date_issued,
        disbusement_date,
        date_first_payment_due,
        tern,
        days,
        installment,
        type,
        payment_method,
        comments,
      };
    },
    userid() {
      const card_data = this.$store.state.admin_loan.cardData || {};
      return card_data.userid;
    },
    paddedCardNumber() {
      return this.formattedCardData.PaddedCardNumber;
    },

    formattedCardData() {
      const {
        BillingDetails,
        CreditCardTransactionResults,
        IPGeoLocationResults,
        BinCheckResults,
        FraudControlResults,
        ThreeDSResponse,
      } = this.cardData;

      const {
        BillToAddress,
        BillToAddress2,
        BillToCity,
        BillToCountry,
        BillToEmail,
        BillToFirstName,
        BillToLastName,
        BillToState,
        BillToTelephone,
        BillToZipPostCode,
        BillToCounty,
        BillToMobile,
      } = BillingDetails || {};

      const {
        AVSResult,
        AuthCode,
        CVV2Result,
        OriginalResponseCode,
        PaddedCardNumber,
        ReasonCode,
        ReasonCodeDescription,
        ReferenceNumber,
        ResponseCode,
        TokenizedPAN,
      } = CreditCardTransactionResults || {};

      const {
        City,
        CountryLong,
        CountryShort,
        DomainName,
        IPAddress,
        IPResponseCode,
        IPResponseCodeDescription,
        ISP,
        Latitude,
        Longitude,
        Region,
        TimeZone,
        ZipCode,
      } = IPGeoLocationResults || {};

      const { BIN, Brand, Country, Issuer, Subtype, Type } =
        BinCheckResults || {};
      const {
        FraudControlId,
        FraudResponseCode,
        //ReasonCode,
        ReasonCodeDesc,
        //ResponseCode,
        Score,
      } = FraudControlResults || {};
      const { AuthenticationResult, CAVV, ECIIndicator, TransactionStain } =
        ThreeDSResponse || {};

      return {
        PaddedCardNumber,
        TokenizedPAN,
        ReasonCode,
        ReasonCodeDescription,
        ReferenceNumber,
        ResponseCode,
        OriginalResponseCode,
        AVSResult,
        AuthCode,
        CVV2Result,
        BillToAddress,
        BillToAddress2,
        BillToCity,
        BillToCountry,
        BillToEmail,
        BillToFirstName,
        BillToLastName,
        BillToState,
        BillToTelephone,
        BillToZipPostCode,
        BillToCounty,
        BillToMobile,
        FraudControlId,
        FraudResponseCode,
        //ReasonCode,
        ReasonCodeDesc,
        //ResponseCode,
        Score,
        City,
        CountryLong,
        CountryShort,
        DomainName,
        IPAddress,
        IPResponseCode,
        IPResponseCodeDescription,
        ISP,
        Latitude,
        Longitude,
        Region,
        TimeZone,
        ZipCode,

        BIN,
        Brand,
        Country,
        Issuer,
        Subtype,
        Type,
        AuthenticationResult,
        CAVV,
        ECIIndicator,
        TransactionStain,
      };
    },
    applicants() {
      const applicants = this.$store.state.admin_loan.applicants;
      const doSafeLowerCase = (val) => (val && val.toLowerCase()) || "";

      applicants.sort((a, b) => {
        const x = doSafeLowerCase(a.family_name);
        const y = doSafeLowerCase(b.family_name);
        if (x < y) return -1;
        if (x > y) return 1;

        const g1 = doSafeLowerCase(a.given_name);
        const g2 = doSafeLowerCase(b.given_name);
        if (g1 < g2) return -1;
        if (g1 > g2) return 1;

        return 0;
      });
      return applicants;
    },
    personalInfo() {
      const applicant = this.$store.state.admin_loan.applicant;
      if (!applicant) return null;
      let tabbedView = formaTabView(applicant);
      tabbedView = tabbedView || {};
      tabbedView.Loan = { ...this.formattedLoanData };
      tabbedView.Card = { ...this.formattedCardData };
      console.log("personalInfo", tabbedView);
      return tabbedView;
    },
  },

  watch: {
    loan: {
      handler: function () {
        this.loaderId = null;
      },
      deep: true,
    },
    loanState: function (x) {
      console.log("loanState:", x);
    },
    adminTab: function (v) {
      console.log("adminTab", v);
    },

    statusSearch: function (val) {
      this.search = val;
    },
    applicantSearch: function (val) {
      this.search = val ? val : "_";
    },
    "$store.state.profile.document": {
      handler: ({ content, filename }) => {
        try {
          const element = document.createElement("a");
          element.setAttribute("href", content);
          element.setAttribute("download", filename);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        } catch (err) {
          console.log("show document error", err);
        }
      },
      deep: true,
    },

    defaultActions: function (val) {
      this.actions = val;
    },

    applicants: {
      handler: function (val) {
        console.log("applicants:", val);
      },
      deep: true,
    },

    "$store.state.profile.cardData": {
      handler: function (_cardData) {
        this.formatCard(_cardData);
      },
      deep: true,
    },

    "$store.state.admin_loan.cardData": {
      handler: function (_cardData) {
        this.formatCard(_cardData);
      },
      deep: true,
    },
  },

  mounted() {},

  methods: {
    formatCard(card_data) {
      console.log("cardData----", card_data);
      const {
        BillingDetails,
        CreditCardTransactionResults,
        IPGeoLocationResults,
        BinCheckResults,
        FraudControlResults,
        ThreeDSResponse,
        userid,
      } = card_data || {};

      this.$set(this.cardData, "BillingDetails", BillingDetails || {});
      this.$set(
        this.cardData,
        "CreditCardTransactionResults",
        CreditCardTransactionResults || {}
      );
      this.$set(
        this.cardData,
        "IPGeoLocationResults",
        IPGeoLocationResults || {}
      );
      this.$set(this.cardData, "BinCheckResults", BinCheckResults || {});
      this.$set(
        this.cardData,
        "FraudControlResults",
        FraudControlResults || {}
      );
      this.$set(this.cardData, "ThreeDSResponse", ThreeDSResponse || {});
      this.$set(this.cardData, "userid", userid);
    },

    setLoan(id) {
      console.log("loan----id", id);
      this.loaderId = id;
      this.$store.dispatch("admin_loan/getAdminLoan", id);
    },

    exitLoan() {
      // this.$refs.adminForm && this.$refs.adminForm.reset();
      this.$store.dispatch("admin_loan/clearLoan");
    },

    filterLoans(value, _, item) {
      const isNameSearch = (() =>
        ((name) =>
          !this.applicantSearch ||
          this.applicantSearch === "_" ||
          name.toUpperCase().indexOf(this.applicantSearch.toUpperCase()) >= 0)(
          this.$options.filters.filterApplicantName(
            item.userid,
            this.applicants
          )
        ))();

      const isStatusSearch = (() =>
        !this.statusSearch ||
        this.statusSearch.toLowerCase() === "any" ||
        this.statusSearch === item.status)();

      return isStatusSearch && isNameSearch;
    },

    getDocument(key) {
      console.log("getDocument key", key);
      this.$store.dispatch("profile/getDocument", key);
    },
  },
};
</script>
