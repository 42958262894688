<template>
  <v-card>
    <v-toolbar color="cyan" dark flat>
      <v-toolbar-title>
        <slot name="title">Profile</slot>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <slot name="control"></slot>

      <template v-slot:extension>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item">
        <v-card flat>
          <v-card-text v-if="item === 'Loan'">
            <v-row
              ><v-col cols="8">
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="nv in loadTab(item)" :key="nv.key">
                        <th width="200px">
                          {{ nv.key }}
                        </th>
                        <td v-if="nv.key === 'Status'">
                          {{ nv.value | displayStatus }}
                        </td>
                        <td v-else v-html="nv.value"></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col>
                <slot name="admin-actions"></slot>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text v-else-if="item === 'Transactions'">
            <slot name="loan-transactions"></slot>
          </v-card-text>

          <v-card-text v-else-if="item === 'Documents'">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Type</th>
                    <th class="text-left">File Name</th>
                    <th class="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="nv in loadTab(item)" :key="nv.key">
                    <td>
                      {{ nv.documentType }}
                    </td>
                    <td>
                      {{ nv.filename }}
                    </td>
                    <td>
                      <slot name="file-control" :filekey="nv.key"> </slot>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>

          <v-card-text v-else-if="item === 'Card'">
            <v-row
              ><v-col cols="8">
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="nv in loadTab(item)" :key="nv.key">
                        <th width="200px">
                          {{ nv.key }}
                        </th>
                        <td v-html="nv.value"></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col>
                <slot name="card-actions" :item="item"></slot>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text v-else>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr v-for="nv in loadTab(item)" :key="nv.key">
                    <th width="200px">
                      {{ nv.key }}
                    </th>
                    <td v-html="nv.value"></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import statusMixins from "@/mixins/statusMixins.js";
import * as changeCase from "change-case";
export default {
  name: "CustomerView",
  mixins: [statusMixins],
  data: () => ({
    loading: false,
    tab: null,
  }),
  props: ["items", "personalInfo"],

  filters: {
    toCase(value, type) {
      return changeCase[type](value);
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return value > 0
        ? formatter.format(value)
        : "(" + formatter.format(-value) + ")";
    },
    filterDate(value) {
      return value.split("T")[0];
    },
    filterApplicantName(value, applicants) {
      let applicant = applicants.find((x) => x.userid === value);
      if (!applicant) return "";
      return `${applicant.given_name} ${applicant.family_name}`;
    },
  },

  computed: {},

  watch: {
    personalInfo: {
      handler: function () {},
      deep: true,
    },
  },

  methods: {
    loadTab(prop) {
      console.log("prop----", prop);
      const formatKey = (key) => {
        if (key === "number_of_dependents") return "dependents";
        if (key === "date_first_payment_due") return "first_payment_due";
        else return key;
      };

      const formatValue = (obj, key) => {
        const value = obj[key];
        if (typeof value === "undefined" || value === null) return "-";
        switch (key) {
          case "address": {
            const { address1, address2, city, country } = value;
            return `<p>${address1}<br/>${address2}<br/>${city}<br/>${country}</p>`;
          }
          case "next_kin": {
            const { name, number, relation } = value;
            return `<p>${name}<br/>Phone:${number}<br/>Relation:${relation}</p>`;
          }
          case "social_media": {
            return value.reduce((_str, x) => {
              const { name, handle } = x;
              _str += `<a href="https://${(
                name || ""
              ).toLowerCase()}.com/${handle}" target="_blank">${name}@${handle}</a><br/>`;
              return _str;
            }, "");
          }
          case "date_applied":
          case "date_approved":
            return (value || "").split("T")[0];
          case "amount_requested":
          case "principal":
          case "installment":
            return this.$options.filters.toCurrency(value);
          default:
            return value;
        }
      };

      const processDisplay = (obj) => {
        return Object.keys(obj).map((key) => ({
          key: changeCase.capitalCase(formatKey(key)),
          value: formatValue(obj, key),
        }));
      };

      let obj = this.personalInfo[prop];
      console.log("obj---1", obj);
      if (prop === "Banking") {
        obj = obj || [];
        return ((x) => processDisplay(x))(
          obj.reduce((_obj, row, i) => {
            row = { ...{ _: `<b>Bank Account ${i + 1}</b>` }, ...row };
            _obj = { ..._obj, ...row };
            return _obj;
          }, {})
        );
      } else if (prop === "Documents") {
        return obj;
      } else if (prop === "Finance") {
        // obj = obj || {};
        //  obj["_"] = `Outstanding Loan`;
        const arr = processDisplay(obj);
        const headerObj = { key: "", value: `<b>Outstanding Loan</b>` };
        arr.unshift(headerObj);
        return arr;
      } else {
        console.log("obj---2", obj);
        obj = obj || {};
        return processDisplay(obj);
      }
    },
  },
};
</script>

<style scoped>
</style>
