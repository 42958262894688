<template>
  <v-container v-if="loan.possible_new_statuses.length > 0">
    <div class="text-center">
      <v-subheader>Admin Actions</v-subheader>
    </div>
    <v-row v-for="_status in loan.possible_new_statuses" :key="_status" dense>
      <v-col>
        <v-btn
          depressed
          block
          :color="_status | setLoanStatusColor"
          @click="openUpdateLoanDialog(_status)"
        >
          {{ _status | makeLoanStatusAction }}
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />

    <v-row v-for="_status in trxStatuses" :key="_status" dense>
      <v-col>
        <v-btn
          v-if="loan.status === 'current'"
          depressed
          block
          outlined
          :color="_status | setTrxStatusColor"
          @click="openTrxDialog(_status)"
        >
          {{ _status | makeTrxStatusAction }}
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="createTransactionDialog"
      hide-overlay
      persistent
      width="600px"
    >
      <v-card tile>
        <v-card-title>
          {{ transaction.type | makeTrxStatusAction }}
        </v-card-title>
        <v-card-text>
          <v-form ref="trxForm" v-model="validTrxForm">
            <v-text-field
              v-model="transaction.amount"
              :rules="[(v) => v > 0 || 'Invalid Amount!']"
              label="Amount"
              :counter="25"
              type="number"
              required
            ></v-text-field>
            <v-text-field
              v-model="transaction.reference"
              label="Reference"
            ></v-text-field>
            <v-checkbox
              v-model="transConfirmed"
              :rules="[(v) => !!v || 'You must agree to continue!']"
              label="Do you agree?"
              required
            ></v-checkbox>
            <v-alert
              v-model="isAlert"
              :type="alertType"
              close-text="Close Alert"
              dismissible
              >{{ alertMessage }}</v-alert
            >
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn
            :disabled="!validTrxForm"
            color="success"
            class="mr-4"
            :loading="creatingTrans"
            @click="doTransaction"
          >
            Submit
          </v-btn>
          <v-btn
            color="error"
            class="mr-4"
            @click="createTransactionDialog = false"
          >
            Exit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loanUpdateDialog" hide-overlay persistent width="600px">
      <v-card tile>
        <v-card-title>
          {{ actions.new_status | makeLoanStatusAction }}
        </v-card-title>

        <v-card-text>
          <v-form ref="adminForm" v-model="validAdminForm">
            <v-container>
              <v-row dense>
                <v-col>
                  <v-select
                    v-if="allowChanges"
                    v-model="actions.borrower_type"
                    label="Borrower Type"
                    required
                    :rules="[(v) => !!v || 'Borrower Type is required']"
                    :items="userTypes"
                  />
                </v-col>
                <v-col>
                  <v-select
                    v-if="allowChanges"
                    v-model="actions.type"
                    label="Loan Type"
                    required
                    :rules="[(v) => !!v || 'Loan Type is required']"
                    :items="loanTypes"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-text-field
                    v-if="allowChanges"
                    v-model="actions.principal"
                    :rules="amountRules"
                    label="Principal"
                    :counter="25"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-if="allowChanges"
                    v-model="actions.days"
                    :rules="daysRules"
                    label="Days"
                    :counter="25"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-menu
                    v-if="allowChanges"
                    v-model="isDateRequiredMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="actions.disbusement_date"
                        label="Disbusement Date"
                        :rules="dateRules"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="actions.disbusement_date"
                      @input="isDateRequiredMenu = false"
                      :max="maxDateRequired"
                      :min="minDateRequired"
                      required
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                    v-if="allowChanges"
                    v-model="isDateDueMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="actions.date_first_payment_due"
                        label="First Payment Due"
                        :rules="dateRules"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="actions.date_first_payment_due"
                      @input="isDateDueMenu = false"
                      :max="maxDateDue"
                      :min="minDateDue"
                      required
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="actions.comments"
                    label="Comments"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="light--text" dense>
                <v-col cols>
                  <v-checkbox
                    v-model="loanUpdateConfirmed"
                    :rules="[(v) => !!v || 'You must agree to continue!']"
                    label="Do you agree?"
                    required
                  ></v-checkbox>
                </v-col>
                <v-col cols>
                  <v-checkbox
                    v-if="allowChanges"
                    v-model="actions.profile_certified"
                    :rules="profileRules"
                    label="Is Profile Certified?"
                    :required="loan.status !== 'in_review'"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn
                    :disabled="!validAdminForm"
                    color="success"
                    class="mr-4"
                    :loading="updatingLoan"
                    @click="updateLoan"
                  >
                    Submit
                  </v-btn>
                  <v-btn
                    color="error"
                    class="mr-4"
                    @click="loanUpdateDialog = false"
                  >
                    Exit
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-alert
          v-model="isAlert"
          :type="alertType"
          close-text="Close Alert"
          dismissible
        >
          {{ alertMessage }}
        </v-alert>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import graphqlMixin from "@/mixins/graphql-mixin.js";
import statusMixins from "@/mixins/statusMixins.js";
import * as changeCase from "change-case";

const modifiableStatuses = ["in_review"];

const userTypes = ["BPO_BASIC", "BPO_ADVANCED", "NON_BPO"];

const loanTypes = ["PayDaySpecial", "PayDay", "Term"];

export default {
  props: ["loan"],
  mixins: [graphqlMixin, statusMixins],
  data: () => ({
    loanTypes,
    userTypes,
    createTransactionDialog: false,
    updatingLoan: false,
    validTrxForm: false,
    isDateRequiredMenu: false,
    isDateDueMenu: false,

    creatingTrans: false,

    loanUpdateDialog: false,
    modifiableStatuses,

    validAdminForm: false,

    loanUpdateConfirmed: false,

    transConfirmed: false,

    actions: {},

    transaction: {
      loan_id: null,
      reference: null,
      linked_transaction_id: null,
      type: null,
      amount: null,
      currency: null,
    },

    dateRules: [(v) => !!v || "Date is required"],
  }),
  filters: {
    toCase(value, type) {
      return changeCase[type](value);
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return value > 0
        ? formatter.format(value)
        : "(" + formatter.format(-value) + ")";
    },
    filterDate(value) {
      return value.split("T")[0];
    },
    filterApplicantName(value, applicants) {
      let applicant = applicants.find((x) => x.userid === value);
      if (!applicant) return "";
      return `${applicant.given_name} ${applicant.family_name}`;
    },
  },

  computed: {
    profileRules() {
      if (this.actions.new_status === "in_review") return [];
      return [(v) => !!v || "Profile Must be certified to continue!"];
    },

    allowChanges() {
      return !!this.modifiableStatuses.find(
        (st) => st === this.actions.new_status
      );
    },

    settings() {
      return this.$store.state.settings.settings;
    },

    setting() {
      return (
        this.settings.find((x) => x.userType === this.actions.borrower_type) ||
        {}
      );
    },

    amountRules() {
      let min;
      let max;

      switch (this.actions.type) {
        case loanTypes[0]: {
          min = this.setting.oneoff_min_amount;
          max = this.setting.oneoff_max_amount;
          break;
        }
        case loanTypes[1]: {
          min = this.setting.payday_daily_min_amount;
          max = this.setting.payday_daily_max_amount;
          break;
        }
        case loanTypes[2]: {
          min = this.setting.term_min_amount;
          max = this.setting.term_max_amount;
        }
      }

      return [
        (v) => !!v || "Amount is required",
        (v) =>
          (v >= min && v <= max) ||
          `Minimum Amount is ${min}, Maximum is ${max}`,
      ];
    },

    daysRules() {
      return [
        (v) => !!v || "Number of Days is required",
        (v) =>
          (v >= this.minDays && v <= this.maxDays) ||
          `Minimum is ${this.minDays}, Maximum is ${this.maxDays}`,
      ];
    },

    minDays() {
      switch (this.actions.type) {
        case loanTypes[0]:
          return this.setting.min_days_oneoff;
        case loanTypes[1]:
          return this.setting.min_days_payday;
        default:
          return this.setting.min_days_term;
      }
    },

    maxDays() {
      switch (this.actions.type) {
        case loanTypes[0]:
          return this.setting.max_days_oneoff;
        case loanTypes[1]:
          return this.setting.max_days_payday;
        default:
          return this.setting.max_days_term;
      }
    },

    minDateDue() {
      const oneDay = 24 * 60 * 60 * 1000;
      return new Date(
        new Date(
          this.actions.disbusement_date || new Date().toISOString()
        ).getTime() + oneDay
      )
        .toISOString()
        .slice(0, 10);
    },
    maxDateDue() {
      //actions.disbusement_date
      const sixtyDays = 60 * 24 * 60 * 60 * 1000;
      return new Date(
        new Date(
          this.actions.disbusement_date || new Date().toISOString()
        ).getTime() + sixtyDays
      )
        .toISOString()
        .slice(0, 10);
    },

    maxDateRequired() {
      const sixtyDays = 60 * 24 * 60 * 60 * 1000;
      return new Date(Date.now() + sixtyDays).toISOString().slice(0, 10);
    },
    minDateRequired() {
      return new Date().toISOString().slice(0, 10);
    },

    defaultActions() {
      if (!this.loan || !this.loan.id) return {};

      let {
        principal,
        borrower_type,
        type,
        days,
        disbusement_date,
        amount_requested,
        date_required,
        date_first_payment_due,
      } = this.loan;
      let new_status = null,
        comments = "";
      principal = principal || amount_requested;
      principal = Math.round(principal * 100) / 100;
      disbusement_date = disbusement_date || date_required;

      return {
        principal,
        borrower_type,
        type,
        days,
        disbusement_date,
        date_first_payment_due,
        new_status,
        comments,
      };
    },
  },

  watch: {
    "loan.id": function () {
      this.loaderId = null;
      this.creatingTrans = false;
      this.updatingLoan = false;
    },
  },
  methods: {
    openTrxDialog(_status) {
      this.createTransactionDialog = true;
      this.$nextTick(() => {
        //this.$refs.trxForm.reset();
        this.transaction.type = _status;
        this.transaction.reference = "";
        this.transaction.amount = 0;
      });
    },
    openUpdateLoanDialog(_status) {
      this.loanUpdateDialog = true;
      this.$nextTick(() => {
        this.actions = { ...this.defaultActions };
        this.actions.new_status = _status;
      });
    },

    async updateLoan() {
      try {
        this.updatingLoan = true;
        const { id, userid } = this.loan;
        const actions = { ...this.actions };
        console.log("actions-2", actions);
        await this.$store.dispatch("admin_loan/adminUpdateLoan", {
          id,
          userid,
          actions,
        });
        this.loanUpdateDialog = false;
        //this.$refs.adminForm && this.$refs.adminForm.reset();
      } catch (err) {
        this.setGraphQlError(err);
      } finally {
        this.loanUpdateConfirmed = false;
        this.updatingLoan = false;
      }
    },

    async doTransaction() {
      try {
        this.creatingTrans = true;
        const { id: loan_id, userid, currency } = this.loan;

        const { reference, type, amount } = this.transaction;

        await this.$store.dispatch("admin_loan/adminCreateLoanTransaction", {
          loan_id,
          userid,
          reference,
          type,
          amount,
          currency,
        });
        //this.$refs.validTrxForm && this.$refs.validTrxForm.reset();
        this.createTransactionDialog = false;
      } catch (err) {
        this.setGraphQlError(err);
      } finally {
        this.transConfirmed = false;
        this.creatingTrans = false;
      }
    },
  },
};
</script>
