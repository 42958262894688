<template>
  <v-card color="white lighten-1">
    <v-card-title>
      <slot></slot>
    </v-card-title>
    <v-tabs
      v-model="userTypeIdx"
      background-color="transparent"
      color="basil"
      grow
    >
      <v-tab v-for="type in USER_TYPES" :key="type.value">{{
        type.text
      }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="userTypeIdx">
      <v-tab-item v-for="type in USER_TYPES" :key="type.value">
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-checkbox
                  v-model="setting.oneoff_active"
                  label="Payday Special"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="setting.payday_daily_active"
                  label="Payday Daily"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="setting.term_active"
                  label="Term Loan"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="setting.payday_oneoff_interest_rate"
                  :rules="rateRules"
                  :counter="4"
                  label="Pay Day One off Interest Rate Required"
                  required
                  type="number"
                />
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="setting.payday_daily_interest_rate"
                  :rules="rateRules"
                  :counter="4"
                  label="Pay Day Daily Interest Rate Required"
                  required
                  type="number"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="setting.term_daily_interest_rate"
                  :rules="rateRules"
                  :counter="4"
                  label="Term Daily Interest Rate Required"
                  required
                  type="number"
                />
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="setting.oneoff_min_amount"
                  :rules="amountRules"
                  :counter="25"
                  label="Special Min Amount Required"
                  required
                  type="number"
                />
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="setting.payday_daily_min_amount"
                  :rules="amountRules"
                  :counter="25"
                  label="Payday Daily Min Amount Required"
                  required
                  type="number"
                />
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="setting.term_min_amount"
                  :rules="amountRules"
                  :counter="25"
                  label="Term Min Amount Required"
                  required
                  type="number"
                />
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="setting.oneoff_max_amount"
                  :rules="amountRules"
                  :counter="25"
                  label="Special Max Amount Required"
                  required
                  type="number"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="setting.payday_daily_max_amount"
                  :rules="amountRules"
                  :counter="25"
                  label="Payday Daily Max Amount Required"
                  required
                  type="number"
                />
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="setting.term_max_amount"
                  :rules="amountRules"
                  :counter="25"
                  label="Term Max Amount Required"
                  required
                  type="number"
                />
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="setting.min_days_oneoff"
                  :rules="minPayDayRules"
                  :counter="4"
                  label="Special Min Days"
                  required
                  type="number"
                />
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="setting.min_days_payday"
                  :rules="minPayDayRules"
                  :counter="4"
                  label="PayDay Min Days"
                  required
                  type="number"
                />
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="setting.min_days_term"
                  :rules="minTermRules"
                  :counter="4"
                  label="Terms Min Days"
                  required
                  type="number"
                />
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="setting.max_days_oneoff"
                  :rules="maxPayDayRules"
                  :counter="4"
                  label="Special Max Days"
                  required
                  type="number"
                />
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="setting.max_days_payday"
                  :rules="maxPayDayRules"
                  :counter="4"
                  label="PayDay Max Days"
                  required
                  type="number"
                />
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="setting.max_days_term"
                  :rules="maxTermRules"
                  :counter="4"
                  label="Terms Max Days"
                  required
                  type="number"
                />
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="setting.processing_fee"
                  :rules="amountRules"
                  :counter="25"
                  label="Processing Fee Required"
                  required
                  type="number"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex">
                <v-subheader> Pay Days</v-subheader>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex" cols="2"
                ><v-subheader>#</v-subheader>
              </v-col>
              <v-col class="d-flex" cols="5"
                ><v-subheader> Min Days</v-subheader>
              </v-col>
              <v-col class="d-flex" cols="5"
                ><v-subheader> Max Days</v-subheader>
              </v-col>
            </v-row>
            <v-row v-for="(p, i) in setting.paydays" :key="'payday-' + i">
              <v-col class="d-flex" cols="2">
                {{ i + 1 }}
              </v-col>
              <v-col class="d-flex" cols="4">
                <span>{{ p.start }}</span>
              </v-col>
              <v-col class="d-flex" cols="4">
                <v-text-field
                  v-model="p.end"
                  :rules="dayRangeRules"
                  :counter="25"
                  required
                  type="number"
                />
              </v-col>
              <v-col class="d-flex" cols="2">
                <v-btn
                  color="success"
                  class="mr-4"
                  @click="addPayDay"
                  :disabled="i < setting.paydays.length - 1"
                  >+
                </v-btn>

                <v-btn
                  color="warning"
                  class="mr-4"
                  @click="deletePayDay(i)"
                  :disabled="i < setting.paydays.length - 1 || i === 0"
                  >-
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  :disabled="!btn_state"
                  color="success"
                  class="mr-4"
                  :loading="loading"
                  @click="updateSettings"
                  >Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import graphqlMixin from "@/mixins/graphql-mixin.js";

const USER_TYPES = [
  { value: "NON_BPO", text: "Non BPO" },
  { value: "BPO_BASIC", text: "BPO Agent" },
  { value: "BPO_ADVANCED", text: "BPO Non-Agent" },
];

export default {
  mixins: [graphqlMixin],
  data: () => ({
    settingChanged: false,
    USER_TYPES,
    userTypeIdx: 0,
    setting: {
      oneoff_active: false,
      payday_daily_active: false,
      term_active: false,
      payday_oneoff_interest_rate: 25,
      payday_daily_interest_rate: 0.9,
      term_daily_interest_rate: 0.9,
      //max_biweekly_terms: 360,
      min_days_term: 30,
      min_days_oneoff: 1,
      min_days_payday: 1,
      max_days_term: 70,
      max_days_oneoff: 30,
      max_days_payday: 28,
      processing_fee: 2500,
      oneoff_max_amount: 10000,
      payday_daily_max_amount: 20000,

      oneoff_min_amount: 10000,
      payday_daily_min_amount: 20000,

      term_min_amount: 50000,
      term_max_amount: 50000,
      paydays: [{ start: 0, end: 0 }],
    },
    valid: false,
    loading: false,

    rateRules: [
      (v) => !!v || "Rate is required",
      (v) => (v >= 0 && v <= 100) || "Invalid Rate",
    ],

    maxTermRules: [
      (v) => !!v || "28 to 360 Only",
      (v) => (v >= 28 && v <= 360) || "Invalid Days",
    ],

    maxPayDayRules: [
      (v) => !!v || "1 to 56 Only",
      (v) => (v >= 1 && v <= 56) || "Invalid Days",
    ],

    minTermRules: [
      (v) => !!v || "14 to 56 Only",
      (v) => (v >= 14 && v <= 56) || "Invalid Days",
    ],

    minPayDayRules: [
      (v) => !!v || "1 to 28 Only",
      (v) => (v >= 1 && v <= 28) || "Invalid Days",
    ],

    amountRules: [
      (v) => !!v || "Amount is required",
      (v) => (v >= 0 && v < 99999999) || "Invalid Amount",
    ],
  }),
  props: [],
  computed: {
    dayRangeRules() {
      return [
        (v) => !!v || `${0} to ${this.paydayMaxDay}`,
        (v) => (v >= 0 && v <= this.paydayMaxDay) || "Invalid #",
      ];
    },

    paydayMinDay() {
      const { min_days_term, min_days_oneoff, min_days_payday } = this.setting;
      return [min_days_term, min_days_oneoff, min_days_payday].reduce(
        (_min, x) => {
          const val = parseInt(x);
          if (_min > val || 0) _min = val;
          return _min;
        },
        0
      );
    },

    paydayMaxDay() {
      const { max_days_term, max_days_oneoff, max_days_payday } = this.setting;
      return [max_days_term, max_days_oneoff, max_days_payday].reduce(
        (_max, x) => {
          const val = parseInt(x);
          if (_max < val || 0) _max = val;
          return _max;
        },
        0
      );
    },
    btn_state() {
      return this.valid && this.settingChanged;
    },
    settings() {
      return this.$store.state.settings.settings;
    },
  },
  watch: {
    setting: {
      handler: function (to, from) {
        console.log("changing....");
        this.settingChanged = to.userType === from.userType;
      },
      deep: true,
    },
    "$store.state.settings.settings": function () {
      this.selectSetting();
    },
    userTypeIdx: function () {
      console.log("changing........");
      this.selectSetting();
    },
  },
  mounted() {
    this.$store.dispatch("settings/getLoanSettings");
  },
  methods: {
    addPayDay() {
      const lastPayDay = this.setting.paydays[this.setting.paydays.length - 1];

      this.setting.paydays.push({
        start: parseInt(lastPayDay.end) + 1,
        end: this.paydayMaxDay,
      });
      this.settingChanged = true;
      this.$forceUpdate();
    },
    deletePayDay(i) {
      this.setting.paydays.splice(i, 1);
      this.settingChanged = true;

      this.$forceUpdate();
    },
    selectSetting() {
      console.log("settings", this.settings);
      const self = this;
      const setting =
        [...(self.settings || [])].find(
          (x) => x.userType === USER_TYPES[self.userTypeIdx].value
        ) || {};
      setting.paydays = [...(setting.paydays || [])].reduce((_arr, x, i) => {
        let p_value = 0;
        if (i > 0) p_value = _arr[i - 1].end + 1;
        _arr.push({ start: p_value, end: parseInt(x) });
        return _arr;
      }, []);
      if (!setting.paydays.length)
        setting.paydays.push({
          start: this.paydayMinDay,
          end: this.paydayMaxDay,
        });
      this.setting = { ...setting };
    },
    async updateSettings() {
      //this.$refs.form.validate();
      try {
        this.loading = true;
        const setting = { ...this.setting };
        setting.paydays = this.setting.paydays.map((x) => x.end);
        setting.userType = USER_TYPES[this.userTypeIdx].value;
        console.log("setting", this.setting);
        await this.$store.dispatch("settings/changeLoanSettings", setting);
        this.settingChanged = false;
      } catch (err) {
        this.setGraphQlError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>